jQuery(document).ready(function () {
    jQuery(".fancybox-thumbs").fancybox({
        openEffect: 'elastic',
        openSpeed: 650,
        arrows: true,
        closeEffect: 'elastic',
        closeSpeed: 650,
        closeBtn: true,
        closeClick: false,
        nextClick: false,
        prevEffect: 'elastic',
        prevSpeed: 650,
        nextEffect: 'elastic',
        nextSpeed: 650,
        helpers: {
            title: {
                type: 'outside'
            },
            thumbs: {
                width: 50,
                height: 50
            }
        }
    });
});
jQuery(document).ready(function () {
    jQuery('.fancybox-media').fancybox({
        openEffect: 'none',
        closeEffect: 'none',
        helpers: {
            media: {}
        }
    });
});
jQuery(document).ready(function () {
    jQuery("area[rel^='prettyPhoto']").prettyPhoto();
    jQuery(".gallery:first a[rel^='prettyPhoto']").prettyPhoto({
        animation_speed: 'normal',
        theme: 'light_square',
        slideshow: 3000,
        allow_resize: true,
        autoplay_slideshow: false
    });
    jQuery(".gallery1:gt(0) a[rel^='prettyPhoto']").prettyPhoto({
        animation_speed: 'fast',
        slideshow: 10000,
        hideflash: true
    });
});

jQuery(document).ready(function () {
    jQuery('#07nqj').show().revolution({
        "delay": 5000,
        "gridheight": 600,
        "gridwidth": 1170,
        "minHeight": 0,
        "autoHeight": "off",
        "sliderType": "standard",
        "sliderLayout": "fullwidth",
        "fullScreenAutoWidth": "on",
        "fullScreenAlignForce": "off",
        "fullScreenOffsetContainer": "",
        "fullScreenOffset": "0",
        "hideCaptionAtLimit": 0,
        "hideAllCaptionAtLimit": 0,
        "hideSliderAtLimit": 0,
        "disableProgressBar": "on",
        "stopAtSlide": -1,
        "stopAfterLoops": -1,
        "shadow": "0",
        "dottedOverlay": "none",
        "startDelay": 0,
        "lazyType": "smart",
        "spinner": "spinner0",
        "shuffle": "off",
        "parallax": {
            "type": "mouse",
            "origo": "slidercenter",
            "speed": 2000,
            "levels": [4, 5, 6, 7, 12, 16, 10, 50, 46, 47, 48, 49, 50, 55]
        },
        "navigation": {
            "onHoverStop": "on",
            "touch": {
                "touchenabled": "on"
            },
            "arrows": {
                "enable": false,
                "left": {
                    "h_align": "left",
                    "v_align": "center",
                    "h_offset": 20,
                    "v_offset": 0
                },
                "right": {
                    "h_align": "right",
                    "v_align": "center",
                    "h_offset": 20,
                    "v_offset": 0
                }
            },
            "bullets": {
                "enable": true,
                "v_align": "bottom",
                "h_align": "center",
                "v_offset": 20,
                "space": 10,
                "tmp": ""
            }
        }
    });
});
